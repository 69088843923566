import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { danger, info, success } from '../../../../components/Alerts';
import { GlobalContext } from '../../../../GlobalContext';
import { getPatient, getPatientsWithSector, setReverterFechamento } from '../../../../utils/api';
import { Table } from '../../../../components/Table';

export default function Closing() {
  const [patients, setPatients] = useState([]);
  const { actions, currentUser } = useContext(GlobalContext);
  const navigate = useNavigate();

  function revertClosing(id, year) {
    setReverterFechamento(id, year).then(json => {
      if(json.error) {
        danger('Falha ao reverter fechamento!');
        console.log(json.message);
      } else {
        success('Fechamento revertido com sucesso!');
        loadPatients();
      }
    })
  }

  function loadPatients() {
    getPatientsWithSector(currentUser.id).then(json => {
      if (json.error) {
        danger(`${json.message}`);
      } else {
        let arr = [];
        json.data.arrPacientes.forEach(patient => {
          console.log(patient.PAC_ALTA_INSTITUCIONAL);
          const patientObj = {
            id: patient.PAC_CODIGO,
            name: decodeURIComponent(escape(patient.PAC_NOME)),
            active: patient.PAC_ALTA_INSTITUCIONAL === 0,
            year: patient.PAC_ANO,
            sector: patient.PAC_SETCAD,
            status: patient.PAC_ALTA_INSTITUCIONAL,
            cpf: patient.PAC_NUMR_CPF,
          };
          !patientObj.active && arr.push(patientObj);
        });
        setPatients(arr);
        console.log(arr);
        !arr.length && info('Ainda não foi cadastrado nenhum paciente!');
      }
    });
  }

  useEffect(() => {
    loadPatients();
  }, []);

  return (
    <div className="animeUp">
      <h2 className="section">Reverter Fechamento</h2>
      <Table
        content={patients}
        typeTable="revert-closing"
        headers={{
          id: 'Prontuário',
          description: 'Nome',
        }}
        buttons={{ edit: false, delete: false, revertClosing: true }}
        revertClosing={revertClosing}
      />
    </div>
  );
}
